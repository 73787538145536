.btn-dark {
  color: #fff;
  background-color: #00909e;
  border-color: #00909e;
}

.btn-dark:hover {
  color: #fff;
  background-color: #00808c;
  border-color: #00808c;
}

.btn-light {
  color: #00626b;
}

.btn-light:hover {
  color: #fff;
  background-color: #00808c;
}

.btn-light.btn-dark {
  color: #fff;
}